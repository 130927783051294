<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard>
          <form id="createCategory" @submit="createCategory">
            <CCardHeader>
              <CCol col="6" style="font-size: 25px">إضافة فئة جديدة</CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CInput
                    label="Name"
                    id="name"
                    v-model="category.name"
                    class="required"
                    placeholder="الاسم باللغة الإنجليزية"
                    required
                  />
                  <div v-if(errors.name) id="validateName" class="invalid-feedback">
                    {{errors.name}}
                  </div>

                  <CInput
                    label="الاسم"
                    id="name_ar"
                    v-model="category.name_ar"
                    class="required"
                    placeholder="الاسم باللغة العربية"
                    required
                  />
                  <div class="form-group">
                    <label for="image">
                      الصورة <span class="star">*</span>
                    </label>
                    <br />
                    <input
                      type="file"
                      id="image"
                      v-on:change="onFileChange"
                      class="form-control"
                      required
                    />
                    <div v-if(errors.image) id="validateName" class="invalid-feedback">
                      {{errors.image}}
                    </div>
                    <small class="form-text text-muted">حجم الصورة يجب ان لايتجاوز 5 ميقا</small>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary"
                ><CIcon name="cil-check-circle" /> حفظ</CButton
              >
              &emsp;
              <CButton type="reset" size="sm" color="danger"
                ><CIcon name="cil-ban" /> مسح
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "CreateCategory",
  data: function () {
    return {
      category: {},
      image: "",
      errors: []
    };
  },
  methods: {
    onFileChange(e) {
      this.category.image = e.target.files[0];
      this.image = e.target.files[0];
      console.log(this.image);
    },
    createCategory: function (e) {
      e.preventDefault();
      let currentObj = this;
      let formData = new FormData();
      if (this.image) {
        if (this.image.size > 5000000) {
          e.preventDefault();
          this.errors.push('image')
          this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
          swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
          return 0;
        } else {
          formData.append("image", this.image);
        }
      }
      else
        (function ReplacingImage() {
          document.getElementById("image").src = "logo2.png";
        });
      formData.append("name", this.category.name);
      formData.append("name_ar", this.category.name_ar);
      this.$http
        .post(`${process.env.VUE_APP_URL}categories`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        .then((response) => {
          currentObj.output = response.data;
          console.log(response.data.error);
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
            currentObj.$router.push({ path: "/categories" });
          } else {
            currentObj.errors = response.data.data;
          }
        })
        .catch(function (error) {
          currentObj.output = error;
        });
    },
  },
};
</script>
